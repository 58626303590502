import { Component, OnInit, Input, Output, OnChanges, EventEmitter } from '@angular/core';
import { FileUploader, FileItem, ParsedResponseHeaders, FileUploaderOptions, Headers } from 'ng2-file-upload';
import { CustomSpinnerService } from 'src/app/core/interceptors/custom-spinner.service';
import { ApplicationWizard } from 'src/app/core/models/application-wizard.model';
import { Artifact } from 'src/app/core/models/artifact.model';
import { HttpService } from 'src/app/core/services/http.service';
import { UtilService } from 'src/app/core/services/util.service';
import { ARTIFACT_KEY } from 'src/app/core/constants/artifacts-order.constants';
import { AppWizService } from 'src/app/core/services/app-wiz.service';
import { ARTIFACT_STATUS_LABEL, ARTIFACT_TYPE } from 'src/app/core/enums/artifact.enum';
import { HttpHeaders } from '@angular/common/http';
import { ErrorLogService } from 'src/app/core/services/error-log.service';
import { UserService } from 'src/app/core/services/user.service';
import { USER_ROLES } from 'src/app/core/enums/user.enum';

@Component({
  selector: 'app-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss']
})
export class FileUploadComponent implements OnInit, OnChanges {

  ARTIFACT_STATUS_LABEL= ARTIFACT_STATUS_LABEL;
  multiple: boolean = false;
  size: number = 4;
  fileUploadId: string = '';
  extension: Array<String> = [];
  isOneDocType = false;
  selectedDocTypes:any = {documentType: {subTypes:[]},documentSubType: {}};

  @Input() uploadSummary: any = [];
  @Input() artifact: Artifact = {} as Artifact;
  @Input() type: string | undefined = '';
  @Input() locked: boolean | undefined = false;
  @Input() artifactUpload: boolean | undefined = false;
  @Input() docTypes: any = null;
  @Input() hideValidation: boolean = false;
  @Input('btnName') btnName: string = 'Upload';
  @Input('appConfig') appConfig: any;

  public uploader: FileUploader = new FileUploader({});
  private uploaderOptions: FileUploaderOptions = {};
  ARTIFACT = ARTIFACT_KEY;
  preSignInData: any;
  
  @Output() emitData: EventEmitter<Boolean> = new EventEmitter();
  @Output() uploadData: EventEmitter<any> = new EventEmitter();

  constructor( 
    private http: HttpService, 
    private spin: CustomSpinnerService,
    private util: UtilService,
    public appWizService : AppWizService,
    public errorLog: ErrorLogService,
    public userService: UserService,
  ) { }

  ngOnInit(): void {
    this.upload();
    this.uploader.onWhenAddingFileFailed = (item, filter) => {
      if (filter.name == 'fileSize') {
        this.util.openSnackBar(`File size exceeds the maximum of ${this.size} MB per file.You can upload mutiple files if required.`, "Error");
        // this.uploadSummary = [];
        // this.uploadSummary.push({
        //   status: 'error',
        //   name: item.name,
        //   size: item.size,
        //   message: `File size exceeds the maximum of ${this.size} MB per file.You can upload mutiple files if required.`,
        // });
      }
      if (filter.name == 'mimeType') {
        this.util.openSnackBar(`The selected file type is not a valid file type. The following file formats only are supported *.${this.extension.join(", *.")}`, "Error");        
        // this.uploadSummary = [];
        // this.uploadSummary.push({
        //   status: 'error',
        //   name: item.name,
        //   size: item.size,
        //   message: `The selected file type is not a valid file type. The following file formats only are supported *.${this.extension.join(", *.")}`,
        // });
      }
    };
    this.uploader._onErrorItem = (item, response, status, headers)=>{
      let message = {
        response:{
          message:`S3 file upload is failed for ${item.url}: ${status}`,
          status:status,
          name: "HttpErrorResponse",
        }
      };
      this.errorLog.handleError({message:JSON.stringify(message)}, 'http');
      this.util.openSnackBar(`S3 file upload is failed`);
      
      item._onError(response, status, headers);
      this.onErrorItem(item, response, status, headers);
    }
  }

  ngOnChanges() {
    setTimeout(()=>this.initDocType());
  }

  initDocType(){
    if(this.docTypes?.length==1){
      this.selectedDocTypes.documentType = this.docTypes[0];
    }else{
      this.selectedDocTypes.documentType = {subTypes:[]};
    }
    if(this.selectedDocTypes.documentType.subTypes.length==1){
      this.selectedDocTypes.documentSubType = this.selectedDocTypes.documentType.subTypes[0];
      this.isOneDocType = true;
    }else{
      this.selectedDocTypes.documentSubType = {};
    }
  }

  ngAfterViewInit() {
    this.uploader.onAfterAddingFile = (item => {
      item.withCredentials = false;
      this.uploader.onErrorItem = (item, response, status, headers) => this.onErrorItem(item, response, status, headers);
      this.uploader.onSuccessItem = (item, response, status, headers) => this.onSuccessItem(item, response, status, headers);
      this.uploader.onCompleteAll = () => this.onCompleteAll();
    });
  }

  onFileSelected(event: any) {

    if(!this.validateFileNames()){
      this.uploader.queue = [];
      this.util.openSnackBar("Filename should be less than or equal to "+this.appConfig.maxFileNameLength + ' characters', 'Error');
    }
    if(this.appConfig.maxFileCount >= (this.uploader.queue.length + this.uploadSummary.length)){
      this.uploader.queue.map((x) => {
        this.sendFile(x);
      });
    }else{
      this.uploader.queue = [];
      this.util.openSnackBar("Max file upload limit is "+this.appConfig.maxFileCount, 'Error');
    }
  }

  validateFileNames(){
    return this.uploader.queue.every(x => x.file.name.length <= 100);
  }

  upload() {
    this.appConfig.maxFileCount = this.appConfig.maxFileCount || 5;
    this.appConfig.maxFileNameLength = this.appConfig.maxFileNameLength || 100;
    this.uploaderOptions['method'] = this.appConfig.method;
    this.uploaderOptions['autoUpload'] = this.appConfig.autoUpload;
    this.uploaderOptions['maxFileSize'] = this.appConfig.maxFileSize;
    this.uploaderOptions['allowedMimeType'] = this.appConfig.allowedMimeType;
    // this.uploaderOptions.queueLimit = this.appConfig.maxFileCount || this.appConfig.queueLimit;
    this.uploader.setOptions(this.uploaderOptions);
    this.multiple = this.appConfig.multiple;
    this.size = this.appConfig.maxFileSize/(1024*1024);
    this.extension = this.appConfig.allowedType;
  }

  sendFile(x: any) {
    let ind = this.uploader.getIndexOfItem(x);
    let name = this.uploader.queue[ind]._file.name;
    const type = this.uploader.queue[ind]._file.type;
    const size = this.uploader.queue[ind]._file.size;

    let headers = new Array();
    headers.push({ name: "Content-Type", value: type });
    this.uploader.queue[ind].method = this.appConfig.method;

    let alias:any = this.selectedDocTypes;
    alias.name = this.selectedDocTypes.documentType.code ? this.selectedDocTypes.documentType.code + " - " : "";
    alias.name += this.selectedDocTypes.documentSubType.code ? this.selectedDocTypes.documentSubType.code + " - " : "";
    alias.name += name;
    this.uploader.queue[ind].alias = JSON.stringify(alias);
    const a = {
      "id": null,
      "appId": this.artifact?.appId,
      "status": ARTIFACT_STATUS_LABEL[this.artifact?.artifactStatus],
      "artifactId": this.artifact?.artifactId,
      "preSignedURL": "",
      // "name": name,
      "name": alias.name,
      "mimeType": type,
      "size": size,
      "note": null,
      "documentType": this.selectedDocTypes.documentType.code || type,
      "documentSubType": this.selectedDocTypes.documentSubType.code || null,
      "storage": null,
      "createDate": new Date()
    };
    
    this.http
      .put('file', a)
      .subscribe((url:any) => {
        const docsRes = {...url};
        docsRes.message = 'File uploaded successfully';
        docsRes.status = 'success';
        this.uploadSummary.push(docsRes);
        
        this.fileUploadId =  url.id
        this.uploader.setOptions({
          url: url.preSignedURL as string,
          method: type,
          headers: headers,
          disableMultipart:true
        });
        this.spin.show();
        this.uploader.queue[ind].upload();
        if(!this.isOneDocType){
          // this.selectedDocTypes = {documentType: {subTypes:[]},documentSubType: {}};
          this.initDocType();
        }
      }, error => {
        this.uploader.queue.splice(ind, 1);
      });
  }
  
  remove(index: any, id: string) {
    this.uploader.queue.splice(index, 1);
    const payload = {
      headers : new HttpHeaders({'Content-Type': 'application/json'}),
      body:{
        "id": id,
        "appId": this.artifact?.appId
      }
    };
    this.http.delete('file', payload)
    .subscribe((x: any) => {
      this.http.delete(x.preSignedURL).subscribe();
      console.log(x);
      this.uploadSummary.splice(index, 1);
      this.checkValid();
      this.updateArtifact();
    });
  }

  onSuccessItem(item: FileItem, response: string, status: number, headers: ParsedResponseHeaders): any {
    this.uploader.removeFromQueue(item);
    this.checkValid();
    this.updateArtifact();
    this.spin.hide();
  }

  updateArtifact() {
    // BM 6-30-23 All file uploads should trigger save
    if(this.artifactUpload && this.artifact.type == ARTIFACT_TYPE.FileUploadArtifact) {
      this.artifact.sectionValid = true;
      this.appWizService.updateAppWizard(this.artifact).subscribe();
    }
  }

  onErrorItem(item: FileItem, response: string, status: number, headers: ParsedResponseHeaders): any {
    let ind = this.uploader.getIndexOfItem(item);
    this.uploader.removeFromQueue(item);
    this.uploadSummary[ind] = {
      id: this.fileUploadId,
      status: 'error',
      name: item.file.name,
      size: item.file.size,
      message: 'An unexpected error has occurred. Please try again.',
    };
    this.checkValid();
    this.spin.hide();
  }

  onCompleteAll () {
    this.uploader.clearQueue();
  }

  checkValid() {
    if(this.uploader.queue.length > 0) this.emitData.emit(true);
    else this.emitData.emit(false);
  }

  validateDocType(){
    if(this.docTypes?.length>1 && !this.selectedDocTypes?.documentType?.code){
      return false;
    }
    if(this.selectedDocTypes?.documentType?.subTypes?.length>1 && !this.selectedDocTypes?.documentSubType?.code){
      return false;
    }
    return true;
  }

  isReadonly(){
    return this.appWizService.isReadOnly(this.artifact);
  }

  viewDocument(item:any) {
    const artifact: Artifact = this.artifact;
    this.http.get('file', {id: item.id}).subscribe((x:any) => {
      if(x.preSignedURL) {
        this.util.urlToBlob(x.preSignedURL, {}, item.name).subscribe();
      }
    });
  }

  checkViewerRoles(item:any){
    const user = this.userService.getUser();
    if(user.alsdeId == this.artifact.userId){
      return true;
    }
    let selectedRole:any  = this.userService.getSelectedSiteRole()?.RoleName;

    if(this.artifact.key == ARTIFACT_KEY.MOREINFO){
      if(item.documentType == 'BGR' && selectedRole!=USER_ROLES.APPLICANT){
        //more-info-needed artifact BGR docs should only be visible by applicant only
        return false;
      }
    }

    if(this.artifact.viewerRoles?.length){
      return this.artifact.viewerRoles.includes(selectedRole);
    }
    return true;
  }
}
