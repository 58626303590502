import { Component, Injector, OnChanges, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { AppWizService } from 'src/app/core/services/app-wiz.service';
import { UtilService } from 'src/app/core/services/util.service';
import { ValidationService } from 'src/app/core/services/validation.service';
import { ArtifactBaseComponent } from '../artifact-base-component';
import { ARTIFACT_KEY } from 'src/app/core/constants/artifacts-order.constants';

@Component({
  selector: 'app-olr-ce-option',
  templateUrl: './olr-ce-option.component.html',
  styleUrls: ['./olr-ce-option.component.scss'],
  encapsulation:ViewEncapsulation.None
})
export class OlrCeOptionComponent extends ArtifactBaseComponent implements OnInit, OnChanges{

  ARTIFACT_KEY = ARTIFACT_KEY;
  options = {
    [ARTIFACT_KEY.OLR_CE_OPTION_PROF]:[
      {
        key:"option1",
        text:"During the valid period of the certificate, the educator earned 50 clock hours of allowable professional development. I have documentation on file verifying the appropriate professional development was completed during the valid period of the certificate.",
      },
      {
        key:"option2",
        text:"During the valid period of the certificate, the educator earned 3 semester hours of allowable credit (coursework). I have an official transcript on file verifying the appropriate coursework was completed during the valid period of the certificate.",
      },
      {
        key:"option3",
        text:"During the valid period of the certificate, the educator earned the initial issuance or renewal of National Board for Professional Teaching Standards (NBPTS) certification. I have the NBPTS certificate on file verifying the certificate was initially earned or renewed during the valid period of the certificate.",
      },
    ],
    [ARTIFACT_KEY.OLR_CE_OPTION_CAREER]:[
      {
        key:"option4",
        text:"During the valid period of the certificate, the educator earned 50 clock hours of allowable professional development. I have documentation on file verifying the appropriate professional development was completed during the valid period of the certificate.",
      },
      {
        key:"option5",
        text:"Career and Technical Education Teacher Certification Program.",
      },
    ],
    [ARTIFACT_KEY.OLR_CE_OPTION_LEAD]:[
      {
        key:"option6",
        text:"During the valid period of the certificate, the educator earned 2 PLUs. Of the required 2 PLUs, at least 1 PLU MUST have been earned through the Alabama Council for Leadership Development (ACLD)- approved Professional Studies. I have documentation on file verifying the appropriate PLUs were completed during the valid period of the certificate.",
      },
      {
        key:"option7",
        text:"During the valid period of the certificate, the educator earned a passing score(s) on the Alabama-prescribed Praxis subject area test for Educational Leadership.",
      },
    ],
  };

  constructor(
    private injector: Injector,
    private fb: FormBuilder,
  ) {
    super(injector)
    this.interalForm = this.fb.group({
      ceOption:    ['', [Validators.required]]
    });
    this.interalForm.valueChanges.subscribe(this.validateSection.bind(this));
  }
  formOptions(){
    return this.options[this.artifact.key]
  }
  initJsonData(){
    this.jsonData = this.jsonData || { formdata: null };
    this.jsonData.formdata = this.jsonData.formdata || {};
    this.interalForm.patchValue(this.jsonData.formdata);
  }
  validateJsonData(){
    this.artifact.sectionValid = this.interalForm.valid;
  }
  resetJsonData(){
    this.jsonData.formdata = {};
    this.interalForm.reset();
  }
  override processBeforeSubmit(){
    this.jsonData = {formdata:this.interalForm.value};
  }



}
