<div>
    <div class="description py-3" *ngIf="artifact.key == ARTIFACT_KEY.OLR_CE_OPTION_CAREER">Please select the continuing education option used to support the career and technical renewal for the applicant.</div>
    <div class="description py-3" *ngIf="artifact.key == ARTIFACT_KEY.OLR_CE_OPTION_PROF">Please select the continuing education option used to support the professional renewal for the applicant.</div>
    <div class="description py-3" *ngIf="artifact.key == ARTIFACT_KEY.OLR_CE_OPTION_LEAD">Please select the continuing education option used to support the leadership renewal for the applicant.</div>
    
    
    <div class="container">
        <div class="row">
            <div class="px-2">
                <form [formGroup]="interalForm">
                    <div class="row form-group">
                        <mat-radio-group aria-label="Select an option" class="example-radio-group artifact-form" formControlName="ceOption">
                            <mat-radio-button *ngFor="let option of formOptions()" [value]="option.text">{{option.text}}</mat-radio-button>
                        </mat-radio-group>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>