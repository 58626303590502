<button mat-icon-button [matMenuTriggerFor]="menu">
    <mat-icon color="accent" style="font-size: 32px;" title="Notes">speaker_notes</mat-icon>
</button>

<mat-menu #menu="matMenu" class="mat-menu-notes">
    <div mat-menu-item [disableRipple]="true">
        <div style="width: 400px;" (click)="$event.stopPropagation();">
            <div class="row">
                <div class="col-12">
                    <h2 class="me-auto">Notes</h2>
                </div>
                <div class="col-12">
                    <form class="p-2" (submit)="addNote()">
                        <div class="col-12">
                            <app-form-control type="textarea" title="Add a note" placeholder="Add a note"
                                controlName="content" [form]="interalForm"></app-form-control>
                        </div>
                        <div class="col-12">
                            <button mat-stroked-button color="accent" class="w-100" type="submit">Send Note</button>
                        </div>
                    </form>
                </div>
                <div class="col-12 border-bottom" *ngFor="let item of notesService.notes">
                    <span class="float-end" style="color: rgba(0, 0, 0, 0.6);">{{item.createdDate | date:'MM/dd/yyyy' }}</span>
                    <div>
                    <h3 class="me-auto mb-1"><b>{{item.creatorId}}</b></h3>
                    <p>{{item.content}}</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</mat-menu>