import { APPLICATION_APPROACH, APPLICATION_STATUS } from "../enums/application.enum";
import { USER_ROLES } from "../enums/user.enum";
import { AlsdeUser } from "./alsde-user.model";
import { Artifact } from "./artifact.model";
import { Case } from "./case.model";

export class IApplicationWizard {
    appId!: string;
    userId!: string;
    applicantId!: string;
    siteIdPrimary!: string;
    siteIdSecondary!: string;
    systemCode!: string;
    siteCode!: string;
    startedBy!: string;
    originatorFirstName!: string;
    originatorLastName!: string;
    status?:{Value:APPLICATION_STATUS} = { Value: APPLICATION_STATUS.STARTED };
    approach: APPLICATION_APPROACH = APPLICATION_APPROACH.OTH;
    feeAmount: number|null = null;
    feeAmountPaid: number|null = null;
    feeSummaries: any[] = [];
    startedDate: Date = new Date();
    submittedDate: Date | null = null;
    packageCompleteDate: Date | null = null;
    lastReviewDate: Date | null = null;
    reviewCompletedDate: Date | null = null;
    decisionDate: Date | null = null;
    user: any = null;
    firstName: string = "";
    lastName: string = "";
    documents: any = [];
    actionableRoles: USER_ROLES[] = [];
    isActionPending: boolean = false; //use in frontend only
    artifacts: Artifact[] = [];
    appExtensionsList:any;
    leaFinanceEmail:string = "";
}
export class ApplicationWizard extends IApplicationWizard {

    constructor(options?: IApplicationWizard) {
        super();
        if (options) {
            this.appId = options.appId ?? this.appId;
            this.userId = options.userId ?? this.userId;
            this.applicantId = options.applicantId ?? this.applicantId;
            this.siteIdPrimary = options.siteIdPrimary ?? this.siteIdPrimary;
            this.siteIdSecondary = options.siteIdSecondary ?? this.siteIdSecondary;
            this.systemCode = options.systemCode ?? this.systemCode;
            this.siteCode = options.siteCode ?? this.siteCode;
            this.startedBy = options.startedBy ?? this.startedBy;
            this.originatorFirstName = options.originatorFirstName ?? this.originatorFirstName;
            this.originatorLastName = options.originatorLastName ?? this.originatorLastName;
            this.status = {Value : options.status?.Value ? options.status?.Value: APPLICATION_STATUS.STARTED };
            this.approach = options.approach ?? this.approach;
            this.feeSummaries = options.feeSummaries ?? this.feeSummaries;
            this.startedDate = options.startedDate ? new Date(options.startedDate) : this.startedDate;
            this.submittedDate = options.submittedDate ? new Date(options.submittedDate) : this.submittedDate;
            this.packageCompleteDate = options.packageCompleteDate ? new Date(options.packageCompleteDate) : this.packageCompleteDate;
            this.lastReviewDate = options.lastReviewDate ? new Date(options.lastReviewDate) : this.lastReviewDate;
            this.reviewCompletedDate = options.reviewCompletedDate ? new Date(options.reviewCompletedDate) : this.reviewCompletedDate;
            this.decisionDate = options.decisionDate ? new Date(options.decisionDate) : this.decisionDate;
            this.user = options.user ?? this.user;
            this.firstName = options.firstName ?? this.firstName;
            this.lastName = options.lastName ?? this.lastName;
            this.user = options.user ?? this.user;
            this.leaFinanceEmail = options.leaFinanceEmail ?? this.leaFinanceEmail;
            this.actionableRoles = options.actionableRoles ?? this.actionableRoles;
            this.appExtensionsList = options.appExtensionsList ?? this.appExtensionsList;
            if(!(this.appExtensionsList.length>0)){
                this.appExtensionsList = [];
                this.appExtensionsList.push({
                    "appType": "TEAMS",
                    "properties": {}
                });
            }
            if(this.feeSummaries.length){
                this.feeSummaries.forEach((feeSummary)=>{
                    feeSummary.items.map((item:any)=>{
                        this.feeAmount += item.feeAmount;
                        this.feeAmountPaid += item.feeAmountPaid;
                    },0);
                });
            }
            if (options.documents) {
                options.documents.forEach((value:any) => {
                    this.documents?.push(value)
                })
            }
            if(options.artifacts){
                options.artifacts.forEach(value =>{
                    this.artifacts?.push(new Artifact(value))
                })
            }
        }
    }

    isApproachLEArenewal(){
        return this.approach.toLowerCase() == APPLICATION_APPROACH.LEA_RENEWAL.toLowerCase();
    }

    isResubmitableApplication(){
        return ![
            APPLICATION_APPROACH.OTH.toLowerCase(),
            APPLICATION_APPROACH.CBT.toLowerCase(),
            APPLICATION_APPROACH.CTF.toLowerCase(),
            APPLICATION_APPROACH.RTF.toLowerCase(),
            APPLICATION_APPROACH.TEAMS.toLowerCase(),
        ].includes(this.approach.toLowerCase());
    }

    toJSON() {
        return {
            appId: this.appId,
            userId: this.userId,
            applicantId: this.applicantId,
            siteIdPrimary: this.siteIdPrimary,
            siteIdSecondary: this.siteIdSecondary,
            systemCode: this.systemCode,
            siteCode: this.siteCode,
            startedBy: this.startedBy,
            originatorFirstName: this.originatorFirstName,
            originatorLastName: this.originatorLastName,
            status: this.status,
            approach: this.approach,
            feeAmount: this.feeAmount,
            feeAmountPaid: this.feeAmountPaid,
            feeSummaries: this.feeSummaries,
            startedDate: this.startedDate,
            submittedDate: this.submittedDate,
            packageCompleteDate: this.packageCompleteDate,
            lastReviewDate: this.lastReviewDate,
            reviewCompletedDate: this.reviewCompletedDate,
            decisionDate: this.decisionDate,
            user: this.user,
            firstName: this.firstName,
            lastName: this.lastName,
            leaFinanceEmail: this.leaFinanceEmail,
            documents: this.documents,
            actionableRoles: this.actionableRoles,
            appExtensionsList: this.appExtensionsList,
            artifacts: this.artifacts.map(i=>i.toJSON()),
        }
    }
}