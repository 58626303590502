<div class="card">
  <div class="card-header">
    <div class="d-flex">
      <h4 class="me-2 mb-0">Deny Application?</h4>
      <mat-icon color="accent" title="Help" class="question-tooltip cursor-pointer"
        (click)="util.openMsgDialog(helpText)">help_outline</mat-icon>
    </div>

  </div>
  <div class="card-body">
    <form [formGroup]="interalForm">
      <p>
        This action cannot be undone. 
        Please select a reason for denying this application from the options below. 
        If none apply, you may enter your own reason in the text field provided.
      </p>
      <div class="row form-group">
        <mat-radio-group aria-label="Select an option" class="example-radio-group d-flex flex-column mb-2" formControlName="rejectReason" (change)="changeValidation()">
          <mat-radio-button *ngFor="let option of options" [value]="option">{{option}}</mat-radio-button>
        </mat-radio-group>
      </div>
      <app-form-control type="textarea" [form]="interalForm" title="Specify Denial Reason" placeholder="Specify Denial Reason"
        controlName="textarea"></app-form-control>
    </form>
  </div>
</div>


<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>Cancel</button>
  <button mat-button [disabled]="interalForm.invalid" (click)="requestApplicantResubmit()">Submit</button>
</mat-dialog-actions>