<div class="panel">
    <mat-expansion-panel>
        <mat-expansion-panel-header>
            <mat-panel-title>
                <mat-icon *ngIf="cert.valid" color="accent" class="panel-icon" title="valid" >book
                </mat-icon>
                <mat-icon *ngIf="!cert.valid" color="accent" class="panel-icon" title="invalid" >book
                </mat-icon>
                <span class="px-2 ">{{cert.title}}</span>
            </mat-panel-title>
            <!-- <mat-panel-description></mat-panel-description> -->
        </mat-expansion-panel-header>
        <div class="content">
            <div class="d-flex flex-row">
                <mat-icon color="accent" class="title-icon me-4 fs-1" *ngIf="cert.renewability == RENEWABILITY.OPEN">cloud_sync</mat-icon>
                <mat-icon color="accent" class="title-icon me-4 fs-1" *ngIf="cert.renewability == RENEWABILITY.CTF">sync</mat-icon>
                <mat-icon color="accent" class="title-icon me-4 fs-1" *ngIf="cert.renewability == RENEWABILITY.OTHER">sync</mat-icon>
                <mat-icon color="accent" class="title-icon me-4 fs-1" *ngIf="cert.renewability == RENEWABILITY.NOT_OPEN">sync_lock</mat-icon>
                <mat-icon color="accent" class="title-icon me-4 fs-1" *ngIf="cert.renewability == RENEWABILITY.NON_RENEWABLE">sync_disabled</mat-icon>
                <div>
                    <div class="item">Valid: <span class="value">
                        {{cert.certDate|date:'mediumDate'}} - {{cert.expires|date:'mediumDate'}}
                    </span> </div>
                    <div *ngIf="cert.status =='Valid'" class="item">Status: <span class="value complete">{{cert.status}}</span> </div>
                    <div *ngIf="cert.status !='Valid'" class="item">Status: <span class="value pending">{{cert.status}}</span></div>
                </div>
            </div>
            
            <p class="mt-2 p-1 bg-success" *ngIf="cert.renewability == RENEWABILITY.OPEN">{{cert.renewabilityDescription}}</p>
            <p class="mt-2 p-1 bg-success" *ngIf="cert.renewability == RENEWABILITY.CTF">{{cert.renewabilityDescription}}</p>
            <p class="mt-2 p-1 bg-success" *ngIf="cert.renewability == RENEWABILITY.OTHER">{{cert.renewabilityDescription}}</p>
            <p class="mt-2 p-1 bg-warning" *ngIf="cert.renewability == RENEWABILITY.NOT_OPEN">{{cert.renewabilityDescription}}</p>
            <p class="mt-2 p-1 bg-danger" *ngIf="cert.renewability == RENEWABILITY.NON_RENEWABLE">{{cert.renewabilityDescription}}</p>

            <h3 class="mb-0 mt-2"><b>Endorsements:</b></h3>
            <ul><li *ngFor="let item of cert.endorsements">{{item.name}}</li></ul>
        </div>
        <a *ngIf="cert.valid" mat-stroked-button color="accent" (click)="downloadCert()" >Download Certificate</a>
    </mat-expansion-panel>
</div>