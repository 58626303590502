import { Component, Input, OnInit } from '@angular/core';
import { Certification, RENEWABILITY } from '../../../core/models/certification.model';
import { HttpService } from 'src/app/core/services/http.service';
import { LocalStorageService } from 'src/app/core/services/local-storage.service';
import { UtilService } from 'src/app/core/services/util.service';

@Component({
  selector: 'app-cert-item',
  templateUrl: './cert-item.component.html',
  styleUrls: ['./cert-item.component.scss']
})
export class CertItemComponent implements OnInit {

  @Input() cert!: Certification
  @Input ('alsdeId') alsdeId: string = "";
  RENEWABILITY = RENEWABILITY;
  constructor( private http: HttpService, private ls: LocalStorageService, public utilService : UtilService ) { }

  ngOnInit(): void {
  }

  downloadCert() {
    const uid = this.alsdeId || JSON.parse(this.ls.getItem('user')).alsdeId;
    this.utilService.urlToBlob('cert', {id: uid},this.cert?.title+".pdf").subscribe();
  }
}
