import { Injectable } from '@angular/core';
import { AppHttpClient } from './app-http-client.service';
import { ApplicationWizard } from '../models/application-wizard.model';
import { map, BehaviorSubject, of, Subject } from 'rxjs';
import { CertificationWizard } from '../models/certification-wizard.model';
import { APPDATA_POST_REQUIRED_ARCTIFACTS, ARTIFACTS_ACCEPTANCE, ARTIFACTS_ORDER, ARTIFACT_KEY } from '../constants/artifacts-order.constants';
import { UtilService } from './util.service';
import { Artifact } from '../models/artifact.model';
import { NOTIFY } from '../constants/notification.constants';
import { Router } from '@angular/router';
import { APPLICATION_STATUS } from '../enums/application.enum';
import { HttpHeaders } from '@angular/common/http';
import _ from 'lodash';
import { ArtifactsMock } from '../mocks/artifacts.mock';

@Injectable({
  providedIn: 'root'
})
export class AppWizService {

  public artifactUpdated$ = new Subject<Artifact>();
  public appUpdated$ = new Subject<ApplicationWizard>();

  appWizard: ApplicationWizard = new ApplicationWizard();
  constructor(
    public http : AppHttpClient, 
    public util : UtilService,
    public router: Router
  ) { }

  createAppWizard(certWizard: CertificationWizard){
    return this.http.put('app', certWizard.toJSON()).pipe(
      map((res:any)=>{
        this.appWizard = new ApplicationWizard(res);
        return this.appWizard;
      })
    );
  }

  getAppWizard(appId:string){
    return this.http.get('app?appId='+appId).pipe(
      map((res:any)=>{
        if(true){
          // res.artifacts.push(ArtifactsMock.artifacts.find(i=>i.key == ARTIFACT_KEY.ACCEPTANCE_LEA_TEAMS));
          // res.artifacts.push(ArtifactsMock.artifacts.find(i=>i.key == ARTIFACT_KEY.CLASS_A_ALT));
          // res.artifacts.push(ArtifactsMock.artifacts.find(i=>i.key == ARTIFACT_KEY.CLASS_A_TRD));
          // res.artifacts.push(ArtifactsMock.artifacts.find(i=>i.key == ARTIFACT_KEY.CLASS_B));
        }

        if(res.artifacts){
          // res.artifacts = res.artifacts.filter((i:any)=>ARTIFACTS_ORDER.includes(i.key));
          res.artifacts = this.util.sortByArray(res.artifacts, ARTIFACTS_ORDER, (t)=>t.key);
        }

        this.appWizard = new ApplicationWizard(res);
        this.appUpdated$.next(this.appWizard);
        return this.appWizard;
      })
    );
  }

  // Method to save the app (wizard) needed on submit of the application
  postAppWizard(){
    return this.http.post('app', this.appWizard.toJSON()).pipe(
      map((res:any)=>{
        if(res.artifacts){
          res.artifacts = this.util.sortByArray(res.artifacts, ARTIFACTS_ORDER, (t)=>t.key);
        }
        this.appWizard = new ApplicationWizard(res);
        return this.appWizard;
      })
    );
  }

  // TODO - BM 6-24-23 this is really update artifact, the naming should represent that
  // also, the returned artifact needs to become the one we are working with
  updateAppWizard(artifact:Artifact){
    if(this.isReadOnly(artifact)){
      return of();
    }
    return this.http.post('artifact', artifact.toJSON()).pipe(
      map((res:any)=>{
        this.util.openToastr('success',NOTIFY.ARTIFACT_SAVE.SUCCESS)
       
        // BM 6-27-23 the artifact is updated notify listeners
        //artifact = new Artifact(res);
        //this.artifactUpdated.next(artifact);

        // BM 6-28-23 update all the artifacts
        let propertiesBackup = this.appWizard.appExtensionsList[0].properties;
        let leaFinanceEmail = this.appWizard.leaFinanceEmail;
        this.getAppWizard(this.appWizard.appId).subscribe(wiz => {
            wiz.artifacts.forEach(art => {
                this.artifactUpdated$.next(new Artifact(art));
            });
            // BM 6-24-23 if the artifact was the signature post the app
            if(APPDATA_POST_REQUIRED_ARCTIFACTS.includes(artifact.key)){
              setTimeout(()=>{
                Object.assign(this.appWizard.appExtensionsList[0].properties,propertiesBackup);
                this.appWizard.leaFinanceEmail = leaFinanceEmail;
                this.postAppWizard().subscribe(res => {
                  if(ARTIFACTS_ACCEPTANCE.includes(artifact.key)){
                      this.util.openToastr('success',NOTIFY.APPLICATION_SAVE.SUCCESS)
                      this.router.navigate(['app']);
                    }
                })
              },200);
            }
        })
      })
    );
  }

  getArtifactJsonData(artifact: Artifact){
    let artifactJsonData = artifact?.artifactJsonData;
    return artifactJsonData ? JSON.parse(artifactJsonData) : artifactJsonData;
  }

  getDocTypes(artifact: Artifact){
    return artifact?.docTypes;
  }

  setArtifactJsonData(artifact: Artifact, artifactJsonData:any){
    let newJsonData = artifactJsonData ? JSON.stringify(artifactJsonData) : artifactJsonData;
    if(this.validateJsonDataIsChanged(artifact.artifactJsonData, newJsonData)){
      artifact.artifactJsonData = newJsonData;
      // artifact.sectionValid = true;
      this.updateAppWizard(artifact).subscribe();
    }
  }

  validateJsonDataIsChanged(object1: any, object2:any){
    return !_.isEqual(JSON.parse(object1), JSON.parse(object2));
  }

  updateDownloadedStatus(artifact: Artifact){
    let observable:any = of(null);
    this.appWizard.artifacts.find(i=>{
      if(i.artifactId == artifact.artifactId){
        i.downloaded = true;
        observable = this.updateAppWizard(i).subscribe();
      }
    });
    return observable;
  } 

  deleteApp(){
    const options = {
      headers: new HttpHeaders({'Content-Type': 'application/json'}),
      body: this.appWizard,
    };
    return this.http.delete('app', options).pipe(
      map(()=>{
        this.util.openToastr('success',NOTIFY.ARTIFACT_DELETE.SUCCESS)
      })
    );
  }

  isReadOnly(artifact:Artifact){
    return artifact.visibility == "readonly" || artifact.locked || this.appWizard.status?.Value == APPLICATION_STATUS.EXPIRED;
  }
}
