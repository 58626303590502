import { Injectable } from '@angular/core';
import { AppHttpClient } from './app-http-client.service';
import { AppWizService } from './app-wiz.service';
import { map } from 'rxjs';
import { INote, Note } from '../models/note.model';

@Injectable({
  providedIn: 'root'
})
export class NotesService {

  appId = "";
  notes: INote[] = [];
  constructor(
    public http: AppHttpClient,
    public appWizService: AppWizService
  ) { 
    this.appWizService.appUpdated$.subscribe(x => {
      this.appId = x.appId;
      this.getNotes();
    });
  }

  getNotes() {
    let applicationId: string = this.appWizService.appWizard.appId;
    return this.http.get('applicationNotes', { applicationId }).subscribe({
      next:(res:any)=>{
        let notes = res.map((i:any)=>new Note(i));
        this.notes = notes.sort((a:INote,b:INote)=>b.createdDate.getTime() - a.createdDate.getTime())
      }
    });
  }
  addNote(note: any) {
    let body = {
      "applicationId": this.appWizService.appWizard.appId,
      "content": note.content,
      "reason": "",
      "category": "Application"
    }
    return this.http.post('applicationNotes', body).pipe(
      map(()=>this.getNotes())
    );
  }


}
