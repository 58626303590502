export enum APPLICATION_STATUS{
    'STARTED' = 'started',
    'FEE-REQUESTED' = 'fee-requested',
    'MOREINFO' = 'more-info-needed',
    'SUBMITED' = 'submitted',
    'COMPLETE' = 'application-package-complete',
    'APPROVED' = 'approved',
    'DELETED' = 'deleted',
    'EXPIRED' = 'expired',
    'DENIED' = 'denied',
}

export enum APPLICATION_APPROACH{
    'OTH' = "OTH",
    'CBT' = "CBT",
    'RTF' = "RTF",
    'CTF' = "CTF",
    'TEAMS' = "TEAMS",
    'LEA_RENEWAL' = "LEA Renewal",
}

export let APPLICATION_ACTION_OPEN_STATUSES = [
    APPLICATION_STATUS.STARTED,
    APPLICATION_STATUS.MOREINFO,
    APPLICATION_STATUS["FEE-REQUESTED"],
];

export let APPLICATION_ACTION_SUBMITED_STATUSES = [
    APPLICATION_STATUS.COMPLETE,
    APPLICATION_STATUS.SUBMITED
];
export let APPLICATION_ACTION_COMPELTE_STATUSES = [
    APPLICATION_STATUS.APPROVED,
    APPLICATION_STATUS.EXPIRED,
    APPLICATION_STATUS.DENIED,
];

