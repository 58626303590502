import { flatMap } from "lodash"
import { Endorsement } from "./endorsement.model"

export enum RENEWABILITY {
    CTF = 'ctf',
    OPEN = 'olr-eligible-open',
    NOT_OPEN = 'olr-eligible-not-open',
    NON_RENEWABLE = 'non-renewable',
}

export class ICertification{
    title!: string
    certDate?: Date | string
    expires?: Date| string
    valid: boolean = false
    category?: string = ""
    type?: string = ""
    status: string = ''
    renewability: RENEWABILITY = RENEWABILITY.NON_RENEWABLE;
    endorsements: Endorsement[] = new Array<Endorsement>();
}

export class Certification extends ICertification{

    constructor(options?: ICertification){
        super();
            if(options){
            this.title = options.title
            this.certDate = options.certDate ? new Date(options.certDate) : undefined;
            this.expires = options.expires ? new Date(options.expires) : undefined;
            this.valid = options.valid,
            this.category = options.category,
            this.type = options.type,
            this.status = options.status
            this.renewability = options.renewability
            if(options.endorsements){
                options.endorsements.forEach(value =>{
                    this.endorsements?.push(new Endorsement(value))
                })
            }
        }
    }

    toJSON(){
        return {
            title: this.title,
            certDate: this.certDate,
            expires: this.expires,
            valid: this.valid,
            category: this.category,
            type: this.type,
            status: this.status,
            renewability: this.renewability,
            endorsements: this.endorsements.map(i=>i.toJSON()),
        }
    }
}