export class INote{
    Id: string = ""
    applicationId: string = ""
    creatorId: string = ""
    content: string = ""
    reason: string = ""
    category: string = ""
    createdDate: Date = new Date()
}

export class Note extends INote{

    constructor(options?: INote){
        super();
            if(options){
            this.Id = options.Id
            this.applicationId = options.applicationId
            this.creatorId = options.creatorId
            this.content = options.content
            this.reason = options.reason
            this.category = options.category
            this.createdDate = new Date(options.createdDate);
            
        }
    }

    toJSON(){
        return {
            Id: this.Id,
            applicationId: this.applicationId,
            creatorId: this.creatorId,
            content: this.content,
            reason: this.reason,
            category: this.category,
            createdDate: this.createdDate,
        }
    }
}