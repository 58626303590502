import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NOTIFY } from 'src/app/core/constants/notification.constants';
import { NotesService } from 'src/app/core/services/notes.service';
import { UtilService } from 'src/app/core/services/util.service';

@Component({
  selector: 'app-app-notes',
  templateUrl: './app-notes.component.html',
  styleUrls: ['./app-notes.component.scss']
})
export class AppNotesComponent {
  interalForm: FormGroup = new FormGroup({});
  constructor(
    public fb: FormBuilder,
    public utilService: UtilService,
    public notesService: NotesService
  ) {
    this.interalForm = this.fb.group({
      content: [null, [Validators.required, Validators.maxLength(100)]]
    });
  }

  addNote(){
    this.notesService.addNote(this.interalForm.value).subscribe({
      next:(res:any)=>{
        this.interalForm.reset();
        this.utilService.openToastr('success',NOTIFY.NOTE.SUCCESS)
      }
    });
  }

}
