// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  apiURL: 'https://api.dev.alsde-cert.com/api/',
  aimURL: 'https://dnv4pdmvrxdx9.cloudfront.net/login.html',
  mockApiURL: 'http://localhost:3000/api',
  externalUrl: {
    fieldPrint:"https://fieldprintalabama.com/",
    alabamaachieves:"https://www.alabamaachieves.org",
    certificationBasedOnTestCbt:"https://www.alabamaachieves.org/2022/07/certification-based-on-test-cbt/",
    atfProfessional:"https://www.alabamaachieves.org/2022/10/atf-professional/",
    ctcAdditionalTeachingDe:"https://www.alabamaachieves.org/2022/10/ctc-additional-teaching-de/",
    driverAndTrafficSafetyEducation:"https://www.alabamaachieves.org/2022/07/driver-and-traffic-safety-education/",
    substituteLicense:"https://www.alabamaachieves.org/2022/06/substitute-license/",
    emergency:"https://www.alabamaachieves.org/2022/06/emergency/",
    ctcTemporaryCertificates:"https://www.alabamaachieves.org/2022/10/ctc-temporary-certificates/",
    alsdeAce:"https://www.alsde.edu/ACE",
    alsdeUserProfile:"https://stageaim.alsde.edu/UserProfile.aspx",
  },
  social:{
    facebook:"https://www.facebook.com/ALTeachingandLeading",
    instagram:"https://www.instagram.com/teaching_and_leading_alabama",
    twitter:"https://twitter.com/TLAlabama",
    linkedin:"https://www.linkedin.com/company/alabama-department-of-education/",
  }
  
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
