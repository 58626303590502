import { Component, Inject } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { Router } from '@angular/router';
import { NOTIFY } from 'src/app/core/constants/notification.constants';
import { HttpService } from 'src/app/core/services/http.service';
import { UtilService } from 'src/app/core/services/util.service';


@Component({
  selector: 'app-deny-application-dialog',
  templateUrl: './deny-application-dialog.component.html',
  styleUrls: ['./deny-application-dialog.component.scss']
})
export class DenyApplicationComponent {
  appId: string = "";
  helpText = `This action cannot be undone. 
  Please select a reason for denying this application from the options below. 
  If none apply, you may enter your own reason in the text field provided.`;
  interalForm

  options = [
    "No Alabama Driver’s License",
    "Requirements not met for option selected. Explanation required (50 character minimum)",
  ]
  constructor(
    private router: Router,
    private http: HttpService,
    private fb: FormBuilder,
    public util: UtilService,
    public dialogRef: MatDialogRef<DenyApplicationComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    this.appId = data.appId;
    this.interalForm = this.fb.group({
      rejectReason: new FormControl("", [Validators.required]),
      textarea: new FormControl({ value: '', disabled: true })
    });
  }

  denialReason(){
    return this.interalForm.value.rejectReason == this.options[this.options.length-1] ? this.interalForm.value.textarea : this.interalForm.value.rejectReason
  }
  requestApplicantResubmit() {
    this.util.openMsgDialog('Are you sure you want to reject this application? This action cannot be undone.', 'Disclaimer', true)
      .afterClosed().subscribe((result) => {
        if (result) {
          this.http.post('app/deny', {
            appId: this.appId,
            denialReason: this.denialReason()
          }).subscribe({
            next: (x: any) => {
              this.closeDialog();
              this.util.openToastr('success', NOTIFY.APPLICATION_DENY.SUCCESS)
              this.router.navigate(['/']);
            },
            error: (err) => {
              console.log(err);
            }
          });
        }
      });
  }

  closeDialog() {
    this.dialogRef.close();
  }

  changeValidation() {
    if (this.interalForm?.get('rejectReason')?.value !== this.options[this.options.length-1]) {
      this.interalForm.controls['textarea'].setValidators([]);
      this.interalForm.controls['textarea'].disable();
    } else {
      this.interalForm.controls['textarea'].setValidators([Validators.required, Validators.minLength(50)]);
      this.interalForm.controls['textarea'].enable();
    }
    this.interalForm.controls['textarea'].updateValueAndValidity();
  }
}
